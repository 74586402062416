import {Button, styled} from "@mui/material";

const NIconButtonDelete = styled(Button)(({theme}) => ({
    marginTop: '8px',
    backgroundColor: theme.info.delete,
    color: theme.palette.background.default,
    fontFamily: 'inherit',
    '& .MuiSvgIcon-root': {
        color: theme.palette.background.default,
    },
    '&:hover': {
        backgroundColor: theme.info.deleteHover
    },
}));

export default NIconButtonDelete;