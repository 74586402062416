import {create} from "zustand";

interface AudioUtilState {
    isGlobeActive: boolean;
}

interface AudioUtilAction {
    toggleGlobe: () => void;
}

type AudioUtilStore = AudioUtilAction & AudioUtilState;

export const useAudioUtilStore = create<AudioUtilStore>((set, get) => ({
    isGlobeActive: true,
    toggleGlobe: () => {
        const {isGlobeActive} = get();
        set({isGlobeActive: !isGlobeActive});
    }
}));