import React, {useState, useEffect, useCallback} from 'react';


/**
 * Adds a EventHandler for resizing a react Component for example card with a static globe in it
 * @param initialSize size of the globe
 * @param ref reference of the react component
 */
function useResizableGlobeSize(initialSize = { width: 1000, height: 1000}, ref: React.MutableRefObject<HTMLDivElement | null>) {
    const [globeSize, setGlobeSize] = useState({
        width: initialSize.width,
        height: initialSize.height,
    });

    const updateGlobeSize = useCallback(() => {
        if (ref.current) {
            const cardWidth = ref.current.offsetWidth;
            const cardHeight = ref.current.offsetHeight;
            setGlobeSize({
                width: cardWidth,
                height: cardHeight,
            });
        }
    }, [ref]);

    useEffect(() => {
        window.addEventListener('resize', updateGlobeSize);
        updateGlobeSize();

        return () => {
            window.removeEventListener('resize', updateGlobeSize);
        };
    }, []);

    return { globeSize, updateGlobeSize };
}

export default useResizableGlobeSize;