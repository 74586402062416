
export function formatDuration(durationInSeconds: number) {
    if (isNaN(durationInSeconds) || durationInSeconds === 0 ||
        durationInSeconds === Infinity ||
        durationInSeconds === -Infinity ||
        durationInSeconds === null ||
        durationInSeconds === undefined ||
        durationInSeconds < 0) {
        return '0:00';
    }
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);

    let formattedTime = '';
    if (hours > 0) {
        formattedTime += hours + ':';
    }
    if (minutes < 10 && hours > 0) {
        formattedTime += '0';
    }
    formattedTime += minutes + ':';
    if (seconds < 10) {
        formattedTime += '0';
    }
    formattedTime += seconds;
    return formattedTime;
}

export function formatTime(time: string) {
    const date = new Date(time);
    return date.toDateString();
}