import {create} from "zustand";
import apiService from "../hooks/apiService";
import {useSnackbarStore} from "./snackbarStore";
import {useAuthStore} from "./authStore";
import {Subscription} from "../types/Subscription";

export const useLemonSqueezyStore = create<any>((set: any, get: any) => ({

    subscription: {
        status: "",
        grace_period_ends_at: "",
        renews_at: "",
    } as Subscription,

    getCheckoutUrl: (email: string, userId: number): Promise<string | void> | undefined => {
        const snackbar = useSnackbarStore.getState();
        if (email === "" || userId === 0) {
            snackbar.showSnackbar("You need to login or Register", "warning");
            return undefined;
        }
        let checkoutData = {
            "userId": `${userId}`,
            "email": email
        }
        return apiService.checkout(checkoutData).then((response: any) => {
            if (response.data && response.data.includes("https://nasbai.lemonsqueezy.com/")) {
                return response.data;
            }
        }).catch((error: any) => {
            console.error(error);
        });
    },

    getSubscriptionUrl: async (): Promise<string | void> => {
        return await apiService.getSubscriptionUrl().then((response: any) => {
            if (response.data && response.data.includes("https://nasbai.lemonsqueezy.com/")) {
                return response.data;
            }
        }).catch((error: any) => {
            if (error.response.status && error.response.status === 401) {
                useAuthStore.getState().logout("Session expired", "warning");
            }
        });
    },
    getSubscriptionInformation: async (): Promise<void> => {
        return await apiService.getSubscriptionInformation().then((response: any) => {
            if (response.data) {
                const sub = {
                    status: response.data.status,
                    grace_period_ends_at: response.data.gracePeriodEndsAt,
                    renews_at: response.data.renewsAt,
                } as Subscription;
                set({subscription: sub});
            }
        }).catch((error: any) => {
            if (error.response.status && error.response.status === 401) {
                useAuthStore.getState().logout("Session expired", "warning");
            }
        });
    }
}));