
import {useEffect, useState} from "react";
import {Country} from "../../types/Country";

function useFetchCountryList() {
    const [countryList, setCountryList] = useState<Country[]>([]);
    const [filteredCountryList, setFilteredCountryList] = useState<Country[]>([]);

    const emptyCountry = {
        id: -1,
        name: 'undefined',
        admo_a3: 'undefined',
        iso_a2: 'undefined',
    } as Country;

    useEffect(() => {
        fetch('./countries_compact.jsonl')
            .then(response => response.text())
            .then(text => {
                const lines = text.split('\n');
                const extractedData: Country[] = lines.map(line => {
                    try {
                        const json = JSON.parse(line);
                        return {
                            id: json.id,
                            name: json.name,
                            admo_a3: json.admo_a3,
                            iso_a2: json.iso_a2,
                        } as Country;
                    } catch (err) {
                        return emptyCountry;
                    }
                }).filter(data => data.id !== -1);
                setCountryList(extractedData);
                setFilteredCountryList(extractedData);
            })
            .catch(error => {
                console.error('Fetch error:', error);
            });
    }, []);

    return { countryList, emptyCountry, filteredCountryList, setFilteredCountryList }

}
export default useFetchCountryList;