import {useMediaQuery} from "@mui/material";
import React from "react";
import "./explorerPage.css";
import ExplorerDesktopPage from "./desktop/explorerDesktopPage";
import ExplorerMobilePage from "./mobile/explorerMobilePage";

const ExplorerPage = () => {
    const isSmallScreen = useMediaQuery('(max-width:900px)');

    return (
        <>
            {isSmallScreen ?
                <ExplorerMobilePage/>
                :
                <ExplorerDesktopPage/>
            }
        </>
    )
}

export default ExplorerPage;