import {create} from "zustand";
import {Signal} from "@preact/signals-react";
import {Playlist} from "../types/Playlist";
import Audio from "../types/Audio";
import apiService from "../hooks/apiService";
import {UserState, useUserStore} from "./userStore";
import {useSnackbarStore} from "./snackbarStore";
import {useAuthStore} from "./authStore";

interface PlaylistState {
    playlists: Signal<Playlist[]>,
}

interface PlaylistActions {
    addPlaylist: (playlist: Playlist) => void,
    insertIntoGlobalPlaylist: (audios: Audio[], countryName: string) => void,
    syncCurrentPlaylistState: (audio: Audio, playlist: Playlist, isPlaying: boolean) => void,

    //Api Calls
    getPlaylist: (user: UserState) => void,
    createPlaylist: (name: string, description: string, user: UserState) => void,
    deletePlaylist: (playlistId: number) => void,
    addAudioToPlaylist: (audio: Audio, playlistId: number) => void,
    removeAudioFromPlaylist: (audio: Audio, playlistId: number) => void,
    acceptAudio: (playlistId: number, audio: Audio) => void,
    rejectAudio: (playlistId: number, audio: Audio) => void,
}

type PlaylistStore = PlaylistState & PlaylistActions;

const globalPlaylist: Playlist = {
    playlistId: 0,
    name: 'Globe Playlist',
    description: '',
    audios: [],
    audioPage: 0,
    user: {
        id: 0,
        username: '',
        email: '',
        roles: [],
    },
    isPlaying: false,
};

/**
 * helper methods
 * @param playlistId the id of the Playlist to get
 * @param playlists the list of Playlists
 */
const getPlaylistById = (playlistId: number, playlists: Playlist[]) => {
    return playlists.find((p: Playlist) => p.playlistId === playlistId);
}

export const usePlaylistStore = create<PlaylistStore>((set, get) => ({
    playlists: new Signal<Playlist[]>([]),

    /**
     * Insert the Audios into the Global Playlist
     * the Global Playlist is the Playlist that is shown for all Users
     * @param audios Array of Audios
     * @param countryName Name of the Country
     */
    insertIntoGlobalPlaylist: (audios: Audio[], countryName) => {
        const {playlists} = get();
        globalPlaylist.description = `${countryName}`;
        globalPlaylist.audios = audios.map((item: any) => ({
            audioId: item.audioId,
            title: item.title,
            filename: item.filename,
            duration: item.duration,
            username: item.username,
            isPlaying: false,
            isReviewed: item.isReviewed,
        } as Audio));
        if (playlists.value.findIndex((p: Playlist) => p.playlistId === globalPlaylist.playlistId) === -1) {
            playlists.value.push(globalPlaylist);
        }
        set({playlists: playlists});
    },

    /**
     * Add a Playlist to the List of Playlists
     * This is used to add a Playlist for the Premium Users only
     *
     * @param playlist Playlist to add
     */
    addPlaylist: (playlist: Playlist) => {
        const {playlists} = get();
        const index = playlists.value.findIndex((p: Playlist) => p.playlistId === playlist.playlistId);
        if (index === -1) {
            playlists.value.push(playlist);
        }
        set({playlists: playlists});
    },
    /*
        Sync the current Playlist State with the Audio
        here we set the isPlaying property of the Audio and the Playlist
        that is currently playing
     */
    syncCurrentPlaylistState: (audio: Audio, playlist: Playlist, isPlaying) => {
        const {playlists} = get();
        const index = playlists.value.findIndex((p: Playlist) => p.playlistId === playlist.playlistId);
        if (index === -1) {
            return;
        }
        const audioIndex = playlists.value[index].audios.findIndex((a: Audio) => a.audioId === audio.audioId);
        if (audioIndex === -1) {
            return;
        }
        playlists.value.forEach((p: Playlist) => {
            p.audios.forEach((a: Audio) => {
                a.isPlaying = false;
            });
            p.isPlaying = false;
        });
        playlists.value[index].audios[audioIndex].isPlaying = isPlaying;
        playlists.value[index].isPlaying = isPlaying;
        set({playlists: playlists});
    },

    getPlaylist: (user: UserState) => {
        const {addPlaylist} = get();
        apiService.getCustomPlaylists(user.id).then((response) => {
            let pl = response.data;
            pl.playlists.forEach((playlist: Playlist) => {
                playlist.isPlaying = false;
                playlist.user = user;
                addPlaylist(playlist);
            });
        }).catch((error) => {
            if (error.response.status && error.response.status === 401) {
                useAuthStore.getState().logout("Session expired", "warning");
                return;
            }
            apiService.playlistHttpError(error);
        });
    },

    createPlaylist: (name: string, description: string, user: UserState) => {
        const {getPlaylist} = get();
        apiService.createPlaylist(name, description, user.id).then((response) => {
            getPlaylist(user);
        }).catch((error) => {
            if (error.response.status && error.response.status === 401) {
                useAuthStore.getState().logout("Session expired", "warning");
                return;
            }
            apiService.playlistHttpError(error);
        });
    },

    deletePlaylist: (playlistId: number) => {
        const {playlists} = get();
        const {id} = useUserStore.getState();
        const index = playlists.value.findIndex((p: Playlist) => p.playlistId === playlistId);
        if (index === -1) {
            return;
        }
        playlists.value.splice(index, 1);
        apiService.deletePlaylist(playlistId, id).then((response) => {
            console.log("Playlist deleted");
        }).catch((error) => {
            apiService.playlistHttpError(error);
        });
        set({playlists: playlists});
    },

    addAudioToPlaylist: (audio: Audio, playlistId: number) => {
        const {playlists} = get();
        const snack = useSnackbarStore.getState();
        const {id} = useUserStore.getState();
        const index = playlists.value.findIndex((p: Playlist) => p.playlistId === playlistId);
        if (index === -1) {
            return;
        }
        apiService.addAudioToPlaylist(id, playlistId, audio.audioId).then((response) => {
            playlists.value[index].audios.push(audio);
            set({playlists: playlists});
            snack.showSnackbar("Audio added to Playlist", "success");
        }).catch((error) => {
            apiService.playlistHttpError(error);
        });
    },

    removeAudioFromPlaylist: (audio: Audio, playlistId: number) => {
        const {playlists} = get();
        const snack = useSnackbarStore.getState();
        const {id} = useUserStore.getState();
        const index = playlists.value.findIndex((p: Playlist) => p.playlistId === playlistId);
        if (index === -1) {
            return;
        }
        apiService.removeAudioFromPlaylist(id, playlistId, audio.audioId).then((response) => {
            const audioIndex = playlists.value[index].audios.findIndex((a: Audio) => a.audioId === audio.audioId);
            playlists.value[index].audios.splice(audioIndex, 1);
            set({playlists: playlists});
            snack.showSnackbar("Audio removed from Playlist", "success");
        }).catch((error) => {
            apiService.playlistHttpError(error);
        });
    },

    acceptAudio: (playlistId: number, audio: Audio) => {
        const snack = useSnackbarStore.getState();
        const currPlaylist = getPlaylistById(playlistId, get().playlists.value);

        apiService.updateReviewStatus(audio.audioId, true).then((response) => {
            snack.showSnackbar("Audio accepted", "success");
            if (currPlaylist) {
                const audioIndex = currPlaylist.audios.findIndex((a: Audio) => a.audioId === audio.audioId);
                if (audioIndex !== -1) {
                    currPlaylist.audios[audioIndex].isReviewed = true;
                    set({playlists: get().playlists});
                }
            }
        }, (error) => {
            snack.showSnackbar("Error accepting Audio", "error");
        });
    },

    rejectAudio: (playlistId, audio) => {
        const snack = useSnackbarStore.getState();
        const currPlaylist = getPlaylistById(playlistId, get().playlists.value);

        apiService.updateReviewStatus(audio.audioId, false).then((response) => {
            snack.showSnackbar("Audio rejected", "success");
            if (currPlaylist) {
                const audioIndex = currPlaylist.audios.findIndex((a: Audio) => a.audioId === audio.audioId);
                if (audioIndex !== -1) {
                    currPlaylist.audios.splice(audioIndex, 1);
                    set({playlists: get().playlists});
                }
            }
        }, (error) => {
            snack.showSnackbar("Error rejecting Audio", "error");
        });
    }

}));
