import {create} from 'zustand';
import {createJSONStorage, persist} from "zustand/middleware";
import {signal} from "@preact/signals-react";
import {useUserStore} from "./userStore";
import {useSnackbarStore} from "./snackbarStore";

interface AuthState {
    isLoggedIn: boolean;
    isLoggingInProgress: boolean;
}

type severity = "error" | "warning" | "info" | "success";

interface AuthActions {
    login: () => void;
    logout: (reason: string, severity: severity) => void;
    setLoggingInProgress: (value: boolean) => void;
}


type AuthStore = AuthState & AuthActions;

export const showRegisterForm = signal(false);
export const plan = signal<SubscriptionPlan>({
    value: 'Free',
    price: 0.0
});

/**
 * Saves the User logged session based on valid jwt Token
 */
export const useAuthStore = create(persist<AuthStore>(
    (set, get) => ({
        isLoggedIn: false,
        isLoggingInProgress: false,
        login: () => {
            const {getProfilePicture, username} = useUserStore.getState();
            getProfilePicture(username, false);
            set({isLoggedIn: true})
        },
        logout: (reason: string, severity: severity) => {
            const logoutDelete = useUserStore.getState().logoutDelete;
            logoutDelete();
            const showSnackbar = useSnackbarStore.getState().showSnackbar;
            if (reason !== "") {
                showSnackbar(reason, severity);
            }
            set({isLoggedIn: false})
        },
        setLoggingInProgress: (value: boolean) => set({isLoggingInProgress: value})
    }),
    {
        name: "session-storage",
        storage: createJSONStorage(() => localStorage)
    }
));
