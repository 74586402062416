import {styled, TextField} from "@mui/material";


const NTextField =  styled(TextField)(({theme}) => ({
    '& .MuiInputBase-root': {
        backgroundColor: theme.palette.background.default,
    },
    '& .MuiInputBase-input': {
        color: theme.palette.text.primary,
    },
    '& .MuiFormLabel-root': {
        color: theme.palette.text.primary,
    },
    '& .MuiFormLabel-root.Mui-focused': {
        color: theme.info.hover,
    },
    '& .MuiFormLabel-root.Mui-error': {
        color: 'red',
    },
    '& .MuiInputBase-root.Mui-error': {
        backgroundColor: theme.palette.background.default,
    }
}));

export default NTextField;