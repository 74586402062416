import {useEffect} from 'react';
import axios from 'axios';
import {useAuthStore} from "../../stores/authStore";
import apiService from "../apiService";
import {signal} from "@preact/signals-react";
import {useUserStore} from "../../stores/userStore";

const isRefreshing = signal(false);

const useRefreshToken = () => {
    const {login, logout} = useAuthStore();
    const { setUser } = useUserStore();
    const source = axios.CancelToken.source();

    function updateUser(data: any) {
        if (data === undefined) {
            return {
                id: 0,
                username: '',
                email: '',
                roles: [],
            }
        }

        return {
            id: data.id,
            username: data.username,
            email: data.email,
            roles: data.roles,
        }
    }

    function refreshToken() {
        if (isRefreshing.value) {
            return;
        }
        isRefreshing.value = true;
        apiService.isAuthenticated().then(res => {
            if (res.status === 202) {
                let user = updateUser(res.data);
                setUser(user);
                return undefined;
            }

            if (res.status === 200 && res.data === 'NOT_AUTHENTICATED') {
                apiService.refreshtoken().then(res => {
                    isRefreshing.value = false;
                    switch (res.data) {
                        case 'NO_USER_LOGGED_IN':
                            logout("", "success");
                            break;
                        case 'REF_TOKEN_EXPIRED':
                            logout("Session expired", "warning");
                            break;
                        case 'REF_TOKEN_INVALID':
                            logout("Session expired", "warning");
                            break;
                        case 'REF_TOKEN_OK':
                            login();
                            break;
                    }
                }).catch(err => {
                    if (err.response.status === 401) {
                        logout("Session expired", "warning");
                    }
                })
            }
        })
    }

    useEffect(() => {
        refreshToken();

        return () => source.cancel('Operation canceled by the user.');
    }, [login, logout]);

    return refreshToken;
};

export default useRefreshToken;