import React, {Suspense} from 'react';
import {Link, Navigate, Route, Routes} from "react-router-dom";
import {useAuthStore} from "../stores/authStore";
import useRefreshToken from "../hooks/auth/useRefreshToken";
import {CookieBanner} from "@keepist/react-gdpr-cookie-banner";
import LandingPage from "./landingPage/LandingPage";
import CoolAppBar from "../components/coolAppBar";
import ExplorerPage from "./explorerPage/explorerPage";
import {createTheme, CssBaseline, styled, ThemeProvider, Typography} from "@mui/material";
import useThemeStore from "../stores/themeStore";
import Box from "@mui/material/Box";


const App = () => {
    useRefreshToken();
    useThemeStore();
    const {isLoggedIn} = useAuthStore();

    const Auth = React.lazy(() => import("./authPage/Auth"));
    const CoolSnackbar = React.lazy(() => import("../components/coolSnackbar"));
    const ProfilePage = React.lazy(() => import("./profilePage/ProfilePage"));
    const PremiumPage = React.lazy(() => import("./premiumPage/PremiumPage"));
    const UploadPage = React.lazy(() => import("./uploadPage/UploadPage"));

    const lightTheme = createTheme({
        palette: {
            mode: 'light',
            primary: {
                main: '#09b146',
            },
            secondary: {
                main: '#eeeeee',
            },
            info: {
                main: '#000000',
            },
            background: {
                paper: '#f2f2f2',
                default: '#ffffff',
            },
            text: {
                primary: '#000000',
                secondary: '#ffffff',
            }
        },
        info: {
            hover: '#0dd547',
            link: '#0724d5',
            selected: '#82bd7b',
            delete: '#d50707',
            deleteHover: '#a10505',
            cancelled: '#f4ab4c'
        },
        typography: {
            fontFamily: 'Ubuntu, sans-serif'
        },
        background: {
            default2: '#f0f0f0',
            default3: '#dddddd'
        },
        scrollbar: {
            thumb: '#09af44',
            track: '#f0f0f0',
            hover: '#09943a'
        }
    });

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#ffffff',
            },
            secondary: {
                main: '#09b146',
            },
            info: {
                main: '#ffffff',
            },
            background: {
                paper: '#1a1a1a',
                default: '#121212',
            },
            text: {
                primary: '#ffffff',
                secondary: '#000000',
            }
        },
        info: {
            hover: '#15bd52',
            link: '#19e864',
            selected: '#1364b0',
            delete: '#e81515',
            deleteHover: '#a10505',
            cancelled: '#f4ab4c'
        },
        typography: {
            fontFamily: 'Ubuntu, sans-serif'
        },
        background: {
            default2: '#262626',
            default3: '#484848'
        },
        scrollbar: {
            thumb: '#09af44',
            track: '#262626',
            hover: '#09943a'
        }
    });

    function getTheme() {
        let theme = localStorage.getItem('theme-storage');
        if (theme) {
            return JSON.parse(theme).state.type === 'dark' ? darkTheme : lightTheme;
        }
        return lightTheme;
    }

    const FooterBox = styled(Box)(({theme}) => ({
        marginTop: '70px',
        height: '100px',
        display: 'flex',
        justifyContent: 'center',
        borderTop: '1px solid #ddd',
        background: `linear-gradient(0deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
        padding: '20px',
    }));

    return (
        <ThemeProvider theme={getTheme()}>
            <CssBaseline/>
            <Suspense fallback={<></>}>
                <CoolSnackbar/>
            </Suspense>
            <CoolAppBar/>
            <Routes>
                <Route path="/" element={<LandingPage/>}></Route>
                <Route path="/explorer" element={isLoggedIn ? (
                    <ExplorerPage/>
                ) : (
                    <Navigate to="/api/auth" replace/>
                )}/>
                <Route path="/api/auth" element={
                    <Suspense fallback={<div>Loading</div>}>
                        <Auth/>
                    </Suspense>
                }/>
                <Route path="/profile/:username" element={isLoggedIn ? (
                    <Suspense fallback={<></>}>
                        <ProfilePage/>
                    </Suspense>
                ) : (
                    <Navigate to="/api/auth" replace/>
                )}/>
                <Route path="*" element={<Typography sx={{mt: '70px'}} variant="h1">404 Not Found</Typography>}/>
                <Route path="/privacy-policy"
                       element={<Typography sx={{mt: '70px'}} variant="h1">Privacy Policy</Typography>}/>
                <Route path="/terms-of-service"
                       element={<Typography sx={{mt: '70px'}} variant="h1">Terms of Service</Typography>}/>
                <Route path="/about" element={<Typography sx={{mt: '70px'}} variant="h1">About</Typography>}/>
                <Route path="/premium" element={
                    <Suspense fallback={<div>Loading</div>}>
                        <PremiumPage/>
                    </Suspense>
                }/>
                <Route path="/upload" element={isLoggedIn ? (
                    <Suspense fallback={<div>Loading</div>}>
                        <UploadPage/>
                    </Suspense>
                ) : (
                    <Navigate to="/api/auth" replace/>
                )}/>
            </Routes>

            <FooterBox>
                <Typography variant="body2" color="text.primary">
                    <Link to="/about" style={{textDecoration: 'none', color: 'inherit'}}>About Us</Link>
                </Typography>
                <Typography variant="body2" color="text.primary" sx={{marginLeft: '20px', marginRight: '20px'}}>
                    <Link to="/terms-of-service" style={{textDecoration: 'none', color: 'inherit'}}>Terms of
                        Service</Link>
                </Typography>
                <Typography variant="body2" color="text.primary">
                    <Link to="/privacy-policy" style={{textDecoration: 'none', color: 'inherit'}}>Privacy Policy</Link>
                </Typography>
            </FooterBox>

            <div className="cookie-banner">
                <CookieBanner
                    message="This website uses cookies to ensure you get the best experience on our website."
                    acceptSelectionButtonText="Accept Selection"
                    showAcceptSelectionButton={true}
                    styles={{
                        message: {
                            minHeight: 32,
                            fontSize: '18px',
                            fontWeight: 500,
                            lineHeight: '130%',
                            padding: '10px 0 0 10px',
                            color: 'rgb(0, 0, 0)'
                        },
                        dialog: {
                            zIndex: 2,
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            width: '100%',
                            backgroundColor: 'white'
                        },
                        selectPane: {
                            padding: '10px 0 10px 0',
                            display: 'flex',
                            alignItems: 'center',
                        },
                        optionWrapper: {
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0 0 0 10px',
                        },
                        optionLabel: {
                            padding: '2px 0 0 22px',
                            display: 'inline-block',
                            position: 'relative',
                        }
                    }}
                />
            </div>
        </ThemeProvider>
    );
}

export default App;
