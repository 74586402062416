import { Slider, styled} from "@mui/material";

const NSlider = styled(Slider)(({theme}) => ({
    color: theme.palette.mode === 'dark' ? '#1A90FF' : '#09af44',
    height: 4,
    '& .MuiSlider-thumb': {
        width: 12,
        height: 12,
        color: 'white',
        transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
        '&::before': {
            boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
        },
        '&:hover, &.Mui-focusVisible': {
            boxShadow: `0px 0px 0px 8px ${
                theme.palette.mode === 'dark'
                    ? 'rgb(255 255 255 / 16%)'
                    : 'rgb(80,177,252 / 16%)'
            }`,
        },
        '&.Mui-active': {
            width: 20,
            height: 20,
        },
    },
    '& .MuiSlider-rail': {
        opacity: 0.28,
    },
}));

export default NSlider;