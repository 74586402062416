import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {Theme, Tooltip, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useSnackbarStore} from "../stores/snackbarStore";
import {useUserStore} from "../stores/userStore";
import {showRegisterForm, useAuthStore} from "../stores/authStore";
import "../scenes/App.css";
import useRefreshToken from "../hooks/auth/useRefreshToken";
import useThemeStore from "../stores/themeStore";
import apiService from "../hooks/apiService";
import NAvatar from "../nasbai-design/NAvatar";
import {AutoAwesome, DarkMode, Login, Logout, Subscriptions} from "@mui/icons-material";
import {useLemonSqueezyStore} from "../stores/lemonSqueezy";

const pages = ['Home', 'Explorer', 'Profile'];

function CoolAppBar() {
    useRefreshToken();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const snackbar = useSnackbarStore();
    const {username, isPremium} = useUserStore();
    const {isLoggedIn, logout} = useAuthStore();
    const {toggleTheme} = useThemeStore();
    const {getSubscriptionUrl} = useLemonSqueezyStore();

    const settintsIcons = [
        premiumIconOption(),
        <DarkMode
            sx={{mr: 1}}
            fontSize="small"/>,
        isLoggedIn ?
            <Login
                sx={{mr: 1}}
                fontSize="small"/> :
            <Logout
                sx={{mr: 1}}
                fontSize="small"/>
    ]

    function premiumIconOption() {
        if (isPremium()) {
            return <Subscriptions
                sx={{mr: 1}}
                fontSize="small"/>
        } else {
            return <AutoAwesome
                sx={{mr: 1}}
                fontSize="small"/>
        }
    }

    function premiumOption() {
        if (!isPremium()) {
            return 'Upgrade';
        } else {
            return 'Manage Subscription';
        }
    }

    const settings = [premiumOption(), 'Dark Mode', isLoggedIn ? 'Logout' : 'Login'];

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = (page: string) => {
        setAnchorElNav(null);
        switch (page) {
            case 'Home':
                navigate('/');
                break;
            case 'Explorer':
                if (isLoggedIn) {
                    navigate('/explorer');
                } else {
                    navigate('/api/auth');
                }
                break;
            case 'Profile':
                if (isLoggedIn) {
                    navigate('/profile/' + username);
                } else {
                    navigate('/api/auth');
                }
                break;
            default:
                navigate('/');
                break;
        }
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    }
    const handleCloseUserMenu = (option: string) => {
        setAnchorElUser(null);
        switch (option) {
            case 'Upgrade':
                navigate('/premium');
                break;
            case 'Manage Subscription':
                getSubscriptionUrl().then((url: string) => {
                    if (url === "" || url === undefined) {
                        snackbar.showSnackbar("You are not subscribed to any plan", "error");
                        return;
                    }
                    window.open(url, '_blank');
                });
                break;
            case 'Dark Mode':
                toggleTheme();
                break;
            case 'Login':
                handleLoginClick();
                break;
            case 'Logout':
                handleLogoutClick();
                break;
            default:
                break;
        }
    }

    function handleLoginClick() {
        showRegisterForm.value = false;
        navigate("/api/auth");
    }

    function handleLogoutClick() {
        apiService.signout().then(r => {
            console.log("Logged out")
            logout("you have been logged out", "success");
        })
    }

    return (
        <AppBar position="static" className="Appbar" sx={{
            color: (theme: Theme) => theme.palette.secondary.main,
            background: (theme: Theme) => theme.palette.background.default,
            fontWeight: 'bold',
            boxShadow: 'none',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            zIndex: 3,
        }}>
            <Toolbar variant="dense">
                <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}, mr: 2}}>
                    <img src="/logo.webp" width={"65em"} className="main-icon" onClick={() => navigate("/")}
                         alt="logo"/>
                </Box>
                <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                    <IconButton
                        size="large"
                        onClick={handleOpenNavMenu}
                        color="primary"
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: {xs: 'block', md: 'none'},
                        }}
                    >
                        {pages.map((page) => (
                            <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
                                <Typography color="text.primary" textAlign="center">{page}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>

                <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}, justifyContent: 'center'}}>
                    <img src="/logo.webp" width={"65em"} height={"65em"} className="main-icon"
                         onClick={() => navigate("/")} alt="logo"/>
                </Box>

                <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                    {pages.map((page) => (
                        <Button
                            key={page}
                            onClick={() => handleCloseNavMenu(page)}
                            sx={{my: 2, fontSize: '1em'}}
                            color="info"
                        >
                            {page}
                        </Button>
                    ))}
                </Box>
                <Box>
                    <Tooltip title={
                        <>
                            <Typography color="inherit">Nasbai Account</Typography>
                            <Typography color="inherit">{username}</Typography>
                        </>
                    }>
                        <IconButton onClick={handleOpenUserMenu}>
                            <NAvatar width={50} height={50} visitor={false}/>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{mt: '45px'}}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {settings.map((setting) => (
                            <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                                {settintsIcons[settings.indexOf(setting)]}
                                <Typography color="text.primary" textAlign="center">{setting}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default CoolAppBar;