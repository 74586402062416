import React, {lazy, Suspense} from 'react';
import {
    Typography
} from "@mui/material";
import "./landingPage.css";
import LandingPageHead from "./header/LandingPageHead";

const LandingPage = () => {

    const LandingPageIntro = lazy(() => import("./intro/LandingPageIntro"));
    const LandingPageCommunity = lazy(() => import("./section1/LandingPageCommunity"));
    const LandingPageSubscription = lazy(() => import("./section2/LandingPageSubscription"));

    return (
        <div className="landing-page-container">
            <LandingPageHead />
            <Suspense fallback={<Typography>Loading</Typography>}>
                <LandingPageIntro/>
            </Suspense>
            <Suspense fallback={<Typography>Loading</Typography>}>
                <LandingPageCommunity/>
            </Suspense>
            <Suspense fallback={<Typography>Loading</Typography>}>
                <LandingPageSubscription />
            </Suspense>
        </div>
    );
};

export default LandingPage;
