import {IconButton, styled} from "@mui/material";

const NIconButton = styled(IconButton)(({theme}) => ({
    marginTop: '8px',
    backgroundColor: theme.palette.primary.main,
    '& .MuiSvgIcon-root': {
        color: theme.palette.background.default,
    },
    '&:hover': {
        backgroundColor: theme.info.hover
    },
}));

export default NIconButton;