import {Button, styled} from "@mui/material";

const NButtonLight = styled(Button)(({theme}) => ({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: theme.info.hover,
        color: 'black'
    },
}));

export default NButtonLight;