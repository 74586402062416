import {create} from "zustand";

interface Snackbar {
    msg: string,
    severity: string,
    switch: boolean,
    showSnackbar: (message: string, severity: string) => void,
}

/**
 * Utility Store to change the Snackbar (coolSnackbar.tsx)
 */
export const useSnackbarStore = create<Snackbar>((set, get) => ({
    switch: false, // the switch triggers the snackbar (coolSnackbar.tsx) to rerender to display the toast message
    msg: '',
    severity: '',
    showSnackbar: (message: string, severity: string) => {
        let snack = get()
        snack.switch = !snack.switch;
        set({msg: message, severity: severity, switch: snack.switch})
    }
}));