import {create} from "zustand";
import {Country} from "../types/Country";
import apiService from "../hooks/apiService";
import axios from "axios";
import {useSnackbarStore} from "./snackbarStore";
import {useAuthStore} from "./authStore";
import {usePlaylistStore} from "./playlistStore";

type AvailableAudio = {
    admoA3: string,
    count: number,
}

interface SelectedCountryState {
    country: Country,
    availableAudios: AvailableAudio[],
}

interface SelectedCountryActions {
    setCountry: (id: number, name: string, admo_a3: string, iso_a2: string) => void,
    getCountryId: () => number,
    getCountryName: () => string,
    getIsoA2: () => string,
    loadGlobePlaylist: () => void,
    getAudioCountByCountry: () => void,
    uploadAudioFile: (data: any) => void,
}

/**
 * Loading States for controlling the Loading Spinner
 */
interface LoadingState {
    isLoading: boolean
    setLoading: (b: boolean) => void,
    isUploadingAudio: boolean,
    setUploadingAudio: (b: boolean) => void,
}

type SelectedCountryStore = SelectedCountryActions & SelectedCountryState & LoadingState;
/**
 * Selected Country Store of the 3D Globe
 * -----------------
 * country:
 *  - The selected Country in the 3D Globe <br>
 *
 * availableAudios:
 *  - The available Audios for each Country <br>
 *  - unReviewed Audios are hidden <br>
 *
 */
export const useSelectedCountryStore = create<SelectedCountryStore>((set, get) => ({
    country: {
        id: -1,
        name: '',
        admo_a3: '',
        iso_a2: '',
    } as Country,
    availableAudios: [] as AvailableAudio[],
    setCountry: (id: number, name: string, admo_a3: string, iso_a2: string) => set((state) => {
        return {
            ...state,
            country: {
                id: id,
                name: name,
                admo_a3: admo_a3,
                iso_a2: iso_a2
            }
        }
    }),
    getCountryId: () => {
        return get().country.id
    },
    getCountryName: () => {
        return get().country.name
    },
    getIsoA2: () => {
        return get().country.iso_a2
    },
    loadGlobePlaylist: () => {
        get().setLoading(true);
        let countryId = get().country.id;
        const countryName = get().country.name;
        const snackbar = useSnackbarStore.getState();

        apiService.getCountryAudio(countryId, axios.CancelToken.source(), 0, 500).then(res => {
            const playlistStore = usePlaylistStore.getState();

            let files = res.data.content;
            if (files.length === 0) {
                snackbar.showSnackbar("No Audios found", "warning");
                return;
            }
            playlistStore.insertIntoGlobalPlaylist(files, countryName);
            get().setLoading(false);
        }).catch(err => {
            if (err.response && err.response.status === 401) {
                const {logout} = useAuthStore.getState();
                logout("Session expired", "warning");
            }
        })
    },
    getAudioCountByCountry: () => {
        const availableAudios = get().availableAudios;
        if (availableAudios.length > 0) {
            return;
        }
        apiService.getAudioCountByCountry().then(res => {
            let data = res.data;
            let availableAudios = data.map((d: any) => {
                return {
                    admoA3: d.admoA3,
                    count: d.count,
                }
            }) as AvailableAudio[];
            set({availableAudios: availableAudios});
        }).catch(err => {
            console.error(err);
        });
    },
    uploadAudioFile: (data: FormData) => {
        apiService.uploadAudioFile(data).then(res => {
            const snackbar = useSnackbarStore.getState();
            snackbar.showSnackbar("File uploaded", "success");
            set({isUploadingAudio: false});
        }).catch(err => {
            const snackbar = useSnackbarStore.getState();
            snackbar.showSnackbar("Error uploading file", "error");
            set({isUploadingAudio: false});
        })
    },
    isLoading: false,
    isUploadingAudio: false,
    setLoading: (b: boolean) => set({isLoading: b}),
    setUploadingAudio: (b: boolean) => set({isUploadingAudio: b}),
}));