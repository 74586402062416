import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";
import apiService from "../hooks/apiService";
import {useSnackbarStore} from "./snackbarStore";
import {useAuthStore} from "./authStore";

/**
 * User State
 * the logged in user
 */
export interface UserState {
    id: number,
    username: string,
    email: string,
    roles: string[],
}

/**
 * Profile User State
 * the user profile that is currently being visited (not the logged in user)
 */
export interface ProfileUserState {
    visitedUser: UserState,
    profilePictureUrl: string,
    visitedProfilePictureUrl: string,
}

interface UserAction {
    setUser: (user: UserState) => void,
    clearUser: () => void,
    isPremium: () => boolean,
    getVisitedUser: (username: string) => void,
    setVisitedUser: (user: UserState) => void,
    getProfilePicture: (username: string | undefined, isVisitor: boolean) => void,
    setProfilePicture: (url: string) => void,
    setVisitedProfilePicture: (url: string) => void,
    uploadProfilePicture: (file: File) => void,
    deleteProfilePicture: () => void,
    deleteUser: () => void,
    logoutDelete: () => void,
}

type User = UserState & UserAction & ProfileUserState;

/**
 * Logged in User State
 */
export const useUserStore = create(persist<User>(
    (set, get) => ({
        id: 0,
        username: '',
        email: '',
        roles: [],
        profilePictureUrl: '',
        visitedUser: {
            id: 0,
            username: '',
            email: '',
            roles: [],
        },
        visitedProfilePictureUrl: '',

        setUser: (user: UserState) => set(() => ({
            id: user.id,
            username: user.username,
            email: user.email,
            roles: user.roles,
        })),
        clearUser: () => {
            set({id: 0, username: '', email: '', roles: []})
        },
        isPremium: () => {
            let roles: string[] = get().roles;
            if (roles === undefined) {
                return false;
            }
            return roles.length !== 0 ? roles.includes("ROLE_PREMIUM") : false;
        },
        getVisitedUser: (username: string) => {
            apiService.getVisitedUser(username).then(res => {
                if (res.data !== null) {
                    set({visitedUser: res.data})
                }
            }).catch(_ => {
                console.log("User not found");
            });
        },
        setVisitedUser: (user: UserState) => {
            set({visitedUser: user});
        },
        getProfilePicture: (username: string | undefined, isVisitor: boolean) => {
            if (username === undefined || username === '') {
                set({profilePictureUrl: ''})
                set({visitedProfilePictureUrl: ''})
                return;
            }
            apiService.getProfilePicture(username).then((res) => {
                if (res.data.byteLength === 0) {
                    if (isVisitor) {
                        set({visitedProfilePictureUrl: ''});
                        return;
                    }
                    set({profilePictureUrl: ''});
                    return;
                }
                const blob = new Blob([res.data], {type: 'image/jpg'});
                if (isVisitor) {
                    set({visitedProfilePictureUrl: URL.createObjectURL(blob)});
                    return;
                }
                set({profilePictureUrl: URL.createObjectURL(blob)});
            }).catch(err => {
                console.log(err);
            });
        },
        setProfilePicture: (url: string) => {
            set({profilePictureUrl: url});
        },
        setVisitedProfilePicture: (url: string) => {
            set({visitedProfilePictureUrl: url});
        },
        uploadProfilePicture: (file: File) => {
            const snackbar = useSnackbarStore.getState();
            apiService.uploadProfilePicture(file).then(() => {
                snackbar.showSnackbar('Profile picture uploaded', 'success');
                set({profilePictureUrl: URL.createObjectURL(file)});
            }).catch(err => {
                snackbar.showSnackbar(err.response.data, 'error');
            });
        },
        logoutDelete: () => {
            let visitedUser = {
                id: 0,
                username: '',
                email: '',
                roles: []
            }
            set({id: 0, username: '', email: '', roles: [], visitedUser: visitedUser, profilePictureUrl: ''});
        },
        deleteUser: () => {
            const userId = get().id;
            const snackbar = useSnackbarStore.getState();
            apiService.deleteUser(userId).then(() => {
                set({id: 0, username: '', email: '', roles: []});
                useAuthStore.getState().logout("User deleted", "warning");
            }).catch(err => {
                snackbar.showSnackbar(err.response.data, 'error');
            });
        },
        deleteProfilePicture: () => {
            const snackbar = useSnackbarStore.getState();
            apiService.deleteProfilePicture().then(() => {
                snackbar.showSnackbar('Profile picture deleted', 'success');
                set({profilePictureUrl: ''});
            }).catch(err => {
                snackbar.showSnackbar(err.response.data, 'error');
            });
        }
    }), {
        name: "user-storage",
        storage: createJSONStorage(() => localStorage)
    }));