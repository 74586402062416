import {Accordion, styled} from "@mui/material";

export const NAccordion = styled(Accordion)(({theme}) => ({
    [theme.breakpoints.up('xs')]: {
        margin: 0,
        '&.MuiAccordion-root.Mui-expanded': {
            marginLeft: '0px',
        },
        '::before': {
            display: 'none'
        },
        '&.MuiAccordion-root.MuiAccordion-rounded': {
            borderRadius: '0px',
        },
        '&.MuiAccordion-root': {
            marginBottom: '10px',
        },
    },
    [theme.breakpoints.up('md')]: {
        marginLeft: '15px',
        '&.MuiAccordion-root.Mui-expanded': {
            marginLeft: '15px',
        },
        '::before': {
            display: 'none'
        },
        '&.MuiAccordion-root.MuiAccordion-rounded': {
            borderRadius: '15px',
        },
        '&.MuiAccordion-root': {
            marginBottom: '10px',
        },
    }
}));
