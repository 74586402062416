import React, {useEffect, useRef, useState, useCallback} from "react";
import GlobeSettings from "../../types/globe";
import {useSelectedCountryStore} from "../../stores/selectedCountryStore";
import useFetchCountryList from "../../hooks/data/fetchCountriesList";
import {Country} from "../../types/Country";
import Globe from "react-globe.gl";

const CoolGlobe = (props: GlobeSettings) => {
    const [countries, setCountries] = useState({features: []});
    const [hoverD, setHoverD] = useState<any>(null);
    const [selectedD, setSelectedD] = useState<any>(null);
    const globeEl = useRef<any>();
    const selAudioStore = useSelectedCountryStore();
    const {countryList, emptyCountry} = useFetchCountryList();

    const fetchJson = () => {
        fetch('./mapSkin.geojson')
            .then(response => response.json())
            .then((response) => {
                const features = response.features.map((feature: any) => {
                    const availableAudio = selAudioStore.availableAudios.find((d) => d.admoA3 === feature.properties.ADM0_A3);
                    if (availableAudio) {
                        feature.properties.amountOfAudios = availableAudio.count;
                    } else {
                        feature.properties.amountOfAudios = 0;
                    }
                    return feature;
                });
                setCountries({features: features});
            })
            .catch(error => console.error("Error fetching map data:", error));
    };

    useEffect(() => {
        selAudioStore.getAudioCountByCountry();
    }, []);

    useEffect(() => {
        if (selAudioStore.availableAudios.length !== 0) {
            fetchJson();
        }
    }, [selAudioStore.availableAudios]);

    useEffect(() => {
        if (globeEl.current) {
            globeEl.current.controls().autoRotate = props.autoRotate;
        }

    }, [props.autoRotate]);

    const selectCountry = (polygon: any) => {
        const selectedCountry = countryList.find((d: Country) => d.admo_a3 === polygon.properties.ADM0_A3);

        if (selectedCountry && selectedCountry !== emptyCountry) {
            selAudioStore.setCountry(selectedCountry.id, selectedCountry.name, selectedCountry.admo_a3, selectedCountry.iso_a2);
            selAudioStore.setLoading(true);
            setSelectedD(polygon);
            selAudioStore.loadGlobePlaylist();
        }
    };

    const polygonCapColor = (d: any) => {
        if (d === selectedD) {
            return '#81ffe9';
        } else if (d === hoverD) {
            return '#1dc76b';
        } else {
            const amountOfAudios = d.properties.amountOfAudios;
            return 'rgba(0,255,171,' + (amountOfAudios / 100) + ')';
        }
    }

    return (
        <Globe
            ref={globeEl}
            globeImageUrl="/images/earth-blue-marble.avif"
            backgroundImageUrl="/images/night-sky.avif"
            lineHoverPrecision={0}
            width={props.width}
            height={props.height}
            polygonsData={countries.features}
            polygonAltitude={useCallback((d: any) => d === hoverD ? 0.04 : 0.01, [hoverD])}
            polygonCapColor={useCallback((d: any) => polygonCapColor(d), [selectedD, hoverD])}
            polygonSideColor={() => 'rgba(0,255,171,0.0)'}
            polygonStrokeColor={() => '#111'}
            polygonCapCurvatureResolution={useCallback(() => 7, [])}
            rendererConfig={{antialias: true, preserveDrawingBuffer: true}}
            //@ts-ignore
            polygonLabel={useCallback(({properties: d}) =>
                `<b>${d.ADMIN} (${d.ADM0_A3})</b> <br /> Available Sounds: ${d.amountOfAudios}`, [])} // use useCallback
            onPolygonHover={setHoverD}
            polygonsTransitionDuration={50}
            onPolygonClick={selectCountry}
        />
    );
};

export default CoolGlobe;
