import {create} from "zustand";
import {MutableRefObject} from "react";
import Audio from "../types/Audio";
import dashjs from "dashjs";
import {Playlist} from "../types/Playlist";
import {usePlaylistStore} from "./playlistStore";

/**
 * Audio Player State
 * -----------------
 *
 * Current Audio File and Playlist must be synchronized with the Playlist Store!
 *
 * currentAudio: The current Audio File <br>
 * currentPlaylist: The current Playlist
 *
 * duration: The Duration of the current Audio File
 * audioRef: The <audio> Reference
 * player: The Dash Player
 */
interface AudioPlayerState {
    currentAudio: Audio,
    currentPlaylist: Playlist,
    duration: number,
    audioRef: MutableRefObject<HTMLAudioElement | null>,
    player: dashjs.MediaPlayerClass,
}

/**
 * Audio Player Actions
 * --------------------
 *
 *  setAudioRef: Set the Audio Reference <br>
 *  initializeDashPlayer: Initialize the Dash Player And Plays the Audio <br>
 *  resetPlayer: Reset the Player <br>
 *  isPlayerInitialized: Check if the Player is Initialized <br>
 *  onClickHandler: Handle the Click Event <br>
 *  pause: Pause the Player <br>
 *  loadCurrentState: Load the Current State of the Audio and Playlist <br>
 *  next: Play the Next Audio File <br>
 *  previous: Play the Previous Audio File
 */
interface AudioPlayerAction {
    setAudioRef: (audioRef: MutableRefObject<HTMLAudioElement | null>) => void,
    initializeDashPlayer: () => void,
    resetPlayer: () => void,
    isPlayerInitialized: boolean,
    onClickHandler: () => void,
    pause: () => void,
    loadCurrentState: (audio: Audio, playlist: Playlist) => void,
    next: () => void,
    previous: () => void,
}

type AudioPlayerStore = AudioPlayerAction & AudioPlayerState;

const API_URL = process.env.REACT_APP_API_URL;

export const useAudioPlayerStore = create<AudioPlayerStore>((set, get) => ({
    duration: 0,
    audioRef: null as any,
    currentAudio: null as any,
    currentPlaylist: null as any,
    isPlayerInitialized: false,
    player: dashjs.MediaPlayer().create(),

    setAudioRef: (audioRef: MutableRefObject<HTMLAudioElement | null>) => {
        set({audioRef: audioRef});
    },

    initializeDashPlayer: () => {
        const player = dashjs.MediaPlayer().create();
        const {audioRef, currentAudio, currentPlaylist} = get();
        if (audioRef.current === null || currentAudio.filename === '') {
            return;
        }
        const {player: previousPlayer} = get();
        if (previousPlayer.isReady() && previousPlayer) {
            previousPlayer.pause();
            previousPlayer.reset();
        }
        player.initialize(audioRef.current, `${API_URL}/api/stream/dash/` + currentAudio.filename + ".mpd", false);
        player.play();
        const {syncCurrentPlaylistState} = usePlaylistStore.getState();
        syncCurrentPlaylistState(currentAudio, currentPlaylist, true);
        set({player: player, isPlayerInitialized: true, duration: currentAudio.duration});
    },

    resetPlayer: () => {
        const {player, currentPlaylist, currentAudio} = get();
        const {syncCurrentPlaylistState} = usePlaylistStore.getState();
        syncCurrentPlaylistState(currentAudio, currentPlaylist, false);
        player.reset();
    },

    onClickHandler: () => {
        const {currentAudio, currentPlaylist, player, initializeDashPlayer} = get();
        const {syncCurrentPlaylistState} = usePlaylistStore.getState();
        if (currentAudio === null || currentPlaylist === null) {
            return;
        }
        if (!player.isReady()) {
            initializeDashPlayer();
            return;
        }
        if (currentAudio.isPlaying) {
            syncCurrentPlaylistState(currentAudio, currentPlaylist, false);
            player.pause();
        } else {
            syncCurrentPlaylistState(currentAudio, currentPlaylist, true);
            player.play();
        }
        set({currentAudio: currentAudio, currentPlaylist: currentPlaylist});
    },

    loadCurrentState: (audio: Audio, playlist: Playlist) => {
        const {syncCurrentPlaylistState} = usePlaylistStore.getState();
        syncCurrentPlaylistState(audio, playlist, true);
        set({currentAudio: audio, currentPlaylist: playlist});
    },

    pause: () => {
        const {player, currentAudio, currentPlaylist} = get();
        const {syncCurrentPlaylistState} = usePlaylistStore.getState();
        player.pause();
        syncCurrentPlaylistState(currentAudio, currentPlaylist, false);
        set({currentAudio: currentAudio, currentPlaylist: currentPlaylist});
    },

    next: () => {
        const {currentPlaylist, currentAudio} = get();
        const {syncCurrentPlaylistState} = usePlaylistStore.getState();
        const index = currentPlaylist.audios.findIndex((audio: Audio) => audio.audioId === currentAudio.audioId);
        if (index + 1 < currentPlaylist.audios.length) {
            currentAudio.isPlaying = false;
            currentPlaylist.audios[index + 1].isPlaying = true;
            syncCurrentPlaylistState(currentAudio, currentPlaylist, false);
            syncCurrentPlaylistState(currentPlaylist.audios[index + 1], currentPlaylist, true);
            set({currentAudio: currentPlaylist.audios[index + 1], currentPlaylist: currentPlaylist});
        } else {
            currentAudio.isPlaying = false;
            currentPlaylist.audios[0].isPlaying = true;
            syncCurrentPlaylistState(currentAudio, currentPlaylist, false);
            syncCurrentPlaylistState(currentPlaylist.audios[0], currentPlaylist, true);
            set({currentAudio: currentPlaylist.audios[0], currentPlaylist: currentPlaylist});
        }
    },

    previous: () => {
        const {currentPlaylist, currentAudio} = get();
        const {syncCurrentPlaylistState} = usePlaylistStore.getState();
        const index = currentPlaylist.audios.findIndex((audio: Audio) => audio.audioId === currentAudio.audioId);
        if (index - 1 >= 0) {
            currentAudio.isPlaying = false;
            currentPlaylist.audios[index - 1].isPlaying = true;
            syncCurrentPlaylistState(currentAudio, currentPlaylist, false);
            syncCurrentPlaylistState(currentPlaylist.audios[index - 1], currentPlaylist, true);
            set({currentAudio: currentPlaylist.audios[index - 1], currentPlaylist: currentPlaylist});
        } else {
            currentAudio.isPlaying = false;
            currentPlaylist.audios[currentPlaylist.audios.length - 1].isPlaying = true;
            syncCurrentPlaylistState(currentAudio, currentPlaylist, false);
            syncCurrentPlaylistState(currentPlaylist.audios[currentPlaylist.audios.length - 1], currentPlaylist, true);
            set({currentAudio: currentPlaylist.audios[currentPlaylist.audios.length - 1], currentPlaylist: currentPlaylist});
        }
    }
}));