import {Avatar} from "@mui/material";
import React from "react";
import {useUserStore} from "../stores/userStore";

const NAvatar = (props: {width?: number, height?: number, visitor: boolean}) => {
    const {profilePictureUrl, visitedProfilePictureUrl} = useUserStore();

    return (
        <Avatar
            src={props.visitor ? visitedProfilePictureUrl : profilePictureUrl}
            sx={{
                width: props.width || 130,
                height: props.height || 130,
                border: (theme) => `2px solid ${theme.palette.info.main}`,
                boxShadow: (theme) => theme.shadows[3],
                background: `linear-gradient(180deg, rgb(12 209 23 / 100%) 0%, rgb(1 68 242 / 100%) 95%)`,
            }}>
        </Avatar>
    );
}

export default NAvatar;