import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

interface ThemeMode {
    type: 'light' | 'dark';
}

interface ThemeAction {
    toggleTheme: () => void;
}

type Theme = ThemeMode & ThemeAction;

function getInitState() {
    let ts = localStorage.getItem('theme-storage');
    let theme = ts ? JSON.parse(ts) : {type: 'light'};
    if (theme.state === undefined) {
        return 'light';
    }
    return theme.state.type;
}

export const useThemeStore = create(persist<Theme>(
    (set, get) => ({
        type: getInitState() as 'light' | 'dark',
        toggleTheme: () => {
            let theme = get();
            if (theme.type) {
                theme.type = theme.type === 'light' ? 'dark' : 'light';
                set({type: theme.type});
            }
        }

    }), {
        name: "theme-storage",
        storage: createJSONStorage(() => localStorage)
    }));

export default useThemeStore;