import {Box, Card} from "@mui/material";
import NButtonLight from "../../../nasbai-design/NButtonLight";
import React, { useEffect, useRef} from "react";
import useResizableGlobeSize from "../../../hooks/globe/resizeGlobeMap";
import {useNavigate} from "react-router-dom";
import CoolGlobe from "../../../components/explorer/coolGlobe";
import {plan, showRegisterForm} from "../../../stores/authStore";

const LandingPageHead = () => {
    const boxRef = useRef<HTMLDivElement | null>(null);
    const cardRef = useRef<HTMLDivElement | null>(null);
    const {globeSize} = useResizableGlobeSize({
        width: 225,
        height: boxRef.current === null ? 500 : boxRef.current?.offsetHeight,
    }, cardRef);
    const navigate = useNavigate();

    useEffect(() => {
        const heroSection = document.querySelector('.hero-section') as HTMLElement;
        const scrollThreshold = 1;

        const handleScroll = () => {
            if (window.scrollY > scrollThreshold) {
                heroSection.classList.add('hidden-hero');
            } else {
                heroSection.classList.remove('hidden-hero');
            }
        };

        window.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    function handleRegisterClick() {
        showRegisterForm.value = true;
        plan.value = {
            value: "Free",
            price: 0.0
        }
        navigate("/api/auth");
    }

    const isSmallScreen = window.innerWidth < 900;

    return (
        <div className="hero-header-container">
            <Box ref={boxRef} className="hero-section">
                <div className="hero-header pulsating-container">
                    <span>Nasbai</span>
                    <br/>Nature Sounds worldwide<br/><br/>
                </div>
                <span className="hero-text"><br/>Discover the World's Natural Beauty <br/> Through Nature Sounds</span>
                <NButtonLight onClick={handleRegisterClick} variant="contained" className="hero-clt" size="large">
                    Register
                </NButtonLight>
            </Box>
            <Card ref={cardRef} className="hero-globe">
                {isSmallScreen ?
                    <img src="/images/night-sky.avif" width={900} height={globeSize.height} alt="globe"/>
                    :
                    <CoolGlobe width={globeSize.width} height={globeSize.height}></CoolGlobe>
                }
            </Card>
        </div>
    );
}

export default LandingPageHead;