import React, {useEffect, useRef} from 'react';
import NIconButton from "../../nasbai-design/NIconButton";
import {useAudioPlayerStore} from "../../stores/audioPlayerStore";
import PauseIcon from "@mui/icons-material/Pause";
import {PlayArrow, SkipNext, SkipPrevious} from "@mui/icons-material";
import {Box, Typography} from "@mui/material";
import NSlider from "../../nasbai-design/NSlider";
import {formatDuration} from "../../hooks/data/formatDuration";
import Grid from "@mui/material/Unstable_Grid2";
import {useSignal} from "@preact/signals-react";
import CoolAudioUtils from "./coolAudioUtils";

const AudioPlayer = () => {
    const audioPlayerStore = useAudioPlayerStore();
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const currentTime = useSignal(0);

    useEffect(() => {
        audioPlayerStore.setAudioRef(audioRef);
        return () => {
            if (audioPlayerStore.isPlayerInitialized) {
                audioPlayerStore.resetPlayer();
            }
        }
    }, []);


    useEffect(() => {
        let tmpAudioRef = audioRef.current;
        if (tmpAudioRef !== null) {
            tmpAudioRef.addEventListener('timeupdate', updateCurrentPlayTime);
        }
        return () => {
            if (tmpAudioRef !== null) {
                tmpAudioRef.removeEventListener('timeupdate', updateCurrentPlayTime);
            }
        }
    }, [audioPlayerStore.isPlayerInitialized]);


    useEffect(() => {
        const audio = audioRef.current;
        if (audio === null) {
            return;
        }

        if ('mediaSession' in navigator) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title: audioPlayerStore.currentAudio?.title || 'Unknown Title',
                artist:  audioPlayerStore.currentAudio?.username || 'Unknown Artist',
                album:'Nasbai',
                artwork: [
                    { src:  'default_artwork.jpg', sizes: '96x96', type: 'image/jpeg' }
                ]
            });

            try {
                navigator.mediaSession.setActionHandler('play', () => {
                    audio.play().then(() => {
                        updatePushNotificationMediaPlayer();
                    });
                });
                navigator.mediaSession.setActionHandler('pause', () => {
                    audio.pause();
                    updatePushNotificationMediaPlayer();
                });
                navigator.mediaSession.setActionHandler('previoustrack', () => {
                    handlePreviousAudio();
                    updatePushNotificationMediaPlayer();
                });
                navigator.mediaSession.setActionHandler('nexttrack', () => {
                    handleNextAudio();
                    updatePushNotificationMediaPlayer();
                });
                navigator.mediaSession.setActionHandler('seekto', (details) => {
                    if (details.fastSeek && 'fastSeek' in audio) {

                        audio.fastSeek(details.seekTime || 0);
                    } else {
                        audio.currentTime = details.seekTime || 0;
                    }
                    updatePushNotificationMediaPlayer();
                });
                navigator.mediaSession.setActionHandler('seekbackward', (details) => {
                    if (!audio.seekable || audio.seekable.length === 0 || details.seekOffset === undefined) {
                        return;
                    }
                    audio.currentTime -= details.seekOffset;
                    updatePushNotificationMediaPlayer();
                });
                navigator.mediaSession.setActionHandler('seekforward', (details) => {
                    if (!audio.seekable || audio.seekable.length === 0 || details.seekOffset === undefined) {
                        return;
                    }
                    audio.currentTime += details.seekOffset;
                    updatePushNotificationMediaPlayer();
                });
            } catch (error) {
                console.error('navigator.mediaSession error:', error);
            }
        }

        return () => {
            if ('mediaSession' in navigator) {
                navigator.mediaSession.metadata = null;
                navigator.mediaSession.setActionHandler('play', null);
                navigator.mediaSession.setActionHandler('pause', null);
                navigator.mediaSession.setActionHandler('previoustrack', null);
                navigator.mediaSession.setActionHandler('nexttrack', null);
                navigator.mediaSession.setPositionState(undefined);
            }
        }
    }, [audioPlayerStore.currentAudio]);


    const updatePushNotificationMediaPlayer = () => {
        const audio = audioRef.current;
        if (audio === null) {
            return;
        }
        if ('setPositionState' in navigator.mediaSession) {
            navigator.mediaSession.setPositionState({
                duration: audio.duration,
                playbackRate: audio.playbackRate,
                position: audio.currentTime,
            });
        }
    }


    function updateCurrentPlayTime() {
        if (audioRef.current !== null) {
            currentTime.value = audioRef.current.currentTime;
        }
    }

    function handleSeekAudio(value: number) {
        if (audioRef.current !== null) {
            audioRef.current.currentTime = value;
            updatePushNotificationMediaPlayer();
        }
    }

    function handlePreviousAudio() {
        if (audioPlayerStore.currentAudio !== null) {
            audioPlayerStore.previous();
            audioPlayerStore.initializeDashPlayer();
        }
    }

    function handleNextAudio() {
        if (audioPlayerStore.currentAudio !== null) {
            audioPlayerStore.next();
            audioPlayerStore.initializeDashPlayer();
        }
    }

    return (
        <Grid sx={{
            width: {
                xs: '100%',
                md: '400px',
                lg: '600px'
            }
        }}>
            <audio ref={audioRef} onEnded={handleNextAudio}></audio>
            <Grid container sx={{width: '100%'}}
                  display="flex" flexDirection="row" alignItems="baseline" justifyContent="space-evenly">
                <Grid>
                    <NIconButton sx={{visibility: 'hidden'}}/>
                </Grid>
                <Grid>
                    <NIconButton onClick={handlePreviousAudio} sx={{bgcolor: t => t.background.default2, mr: 2}}>
                        <SkipPrevious sx={{fill: t => t.palette.primary.main}}/>
                    </NIconButton>
                    <NIconButton onClick={audioPlayerStore.onClickHandler}>
                        {audioPlayerStore.currentAudio?.isPlaying ?
                            <PauseIcon sx={{color: 'black'}}/> :
                            <PlayArrow sx={{color: 'black'}}/>}
                    </NIconButton>
                    <NIconButton onClick={handleNextAudio} sx={{bgcolor: t => t.background.default2, ml: 2}}>
                        <SkipNext sx={{fill: t => t.palette.primary.main}}/>
                    </NIconButton>
                </Grid>
                <Grid>
                    <Box sx={{display: {md: 'none'}}}>
                        <CoolAudioUtils utilSide={false}/>
                    </Box>
                </Grid>
            </Grid>
            <Grid container sx={{width: '100%'}} spacing={1} justifyContent="space-between">
                <Grid xs={1} sm={1} sx={{ml: '1em'}}>
                    <Typography color="text.primary" sx={{
                        fontSize: '11pt',
                        mt: {
                            xs: '0.7em',
                            sm: '0.7em',
                            md: '0.2em'
                        }
                    }}>{formatDuration(currentTime.value)}</Typography>
                </Grid>
                <Grid xs={8} md={8} lg={9}>
                    <NSlider min={0}
                             max={audioPlayerStore.duration}
                             value={currentTime.value}
                             onChange={(_, value) => currentTime.value = value as number}
                             onChangeCommitted={(_, value) => handleSeekAudio(value as number)}/>
                </Grid>
                <Grid xs={1} sm={1} sx={{mr: '1em'}}>
                    <Typography color="text.primary" sx={{
                        fontSize: '11pt',
                        mt: {
                            xs: '0.7em',
                            sm: '0.7em',
                            md: '0.2em'
                        }
                    }}>{formatDuration(audioPlayerStore.duration)}</Typography>
                </Grid>
            </Grid>

        </Grid>
    );
};

export default AudioPlayer;
