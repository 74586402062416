import axios from "axios";
import SigninRequest from "../types/auth/SigninRequest";
import SignupRequest from "../types/auth/SignupRequest";

const API_URL = process.env.REACT_APP_API_URL;

const apiService = {
    /*
        AUTH API
     */
    signin: async (data: SigninRequest, source: any) => {
        return await axios.post(`${API_URL}/api/auth/signin`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
            cancelToken: source.token,
            withCredentials: true,
        });
    },
    signup: async (data: SignupRequest, source: any) => {
        return await axios.post(`${API_URL}/api/auth/signup`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
            cancelToken: source.token,
            withCredentials: true,
        });
    },
    signout: async () => {
        return await axios.post(`${API_URL}/api/auth/signout`, {}, {
            withCredentials: true,
        });
    },
    isAuthenticated: async () => {
        return await axios.get(`${API_URL}/api/auth/isAuthenticated`, {
            withCredentials: true,
        });
    },
    refreshtoken: async () => {
        return await axios.post(`${API_URL}/api/auth/refreshtoken`, {}, {
            withCredentials: true,
        });
    },
    updateUsername: async (username: string) => {
        return await axios.put(`${API_URL}/api/auth/update`, {
            username: username,
            email: null,
            password: null
        }, {
            withCredentials: true,
        });
    },

    /*
       STREAM API
    */
    getCountryAudio: async (countryId: number, source: any, page: number, size: number) => {
        return await axios.get(`${API_URL}/api/stream/countryAudio/${countryId}?page=${page}&size=${size}`, {
            withCredentials: true,
            cancelToken: source.token,
        });
    },
    getAudioCountByCountry: async () => {
        return await axios.get(`${API_URL}/api/stream/countByCountry`, {
            withCredentials: true,
        });
    },
    uploadAudioFile: async (data: FormData) => {
        return await axios.post(`${API_URL}/api/stream/upload`, data, {
            withCredentials: true
        });
    },
    updateReviewStatus: async (audioId: number, isAccepted: boolean) => {
        return await axios.put(`${API_URL}/api/stream/updateReviewStatus`, {
            audioId: audioId,
            isAccepted: isAccepted,
        }, {
            withCredentials: true,
        });
    },
    /*
        PLAYLIST API
     */
    getCustomPlaylists: async (userId: number) => {
        return await axios.get(`${API_URL}/api/playlist/get/` + userId, {
            withCredentials: true,
        });
    },
    createPlaylist: async (name: string, description: string, userId: number) => {
        return await axios.post(`${API_URL}/api/playlist/create`, {
            userId: userId,
            name: name,
            description: description,
        }, {
            withCredentials: true,
        });
    },
    deletePlaylist: async (playlistId: number, userId: number) => {
        return await axios.delete(`${API_URL}/api/playlist/delete/${playlistId}/${userId}`, {
            withCredentials: true,
        });
    },
    addAudioToPlaylist: async (userId: number, playlistId: number, audioId: number) => {
        return await axios.put(`${API_URL}/api/playlist/addAudio`, {
            userId: userId,
            playlistId: playlistId,
            audioId: audioId,
        }, {
            withCredentials: true,
        });
    },
    removeAudioFromPlaylist: async (userId: number, playlistId: number, audioId: number) => {
        return await axios.delete(`${API_URL}/api/playlist/removeAudio/${playlistId}/${audioId}/${userId}`, {
            withCredentials: true,
        });
    },

    /*
        USER API
     */
    getProfilePicture: async (username: string) => {
        return await axios.get(`${API_URL}/api/user/profilePicture/${username}`, {
            responseType: 'arraybuffer',
            withCredentials: true,
        });
    },
    getVisitedUser: async (username: string) => {
        return await axios.get(`${API_URL}/api/user/get/${username}`, {
            withCredentials: true,
        });
    },
    deleteProfilePicture: async () => {
        return await axios.delete(`${API_URL}/api/user/delete/picture`, {
            withCredentials: true,
        });
    },
    uploadProfilePicture: async (file: File) => {
        const formData = new FormData();
        formData.append('file', file);
        return await axios.post(`${API_URL}/api/user/upload/picture`, formData, {
            withCredentials: true,
        });
    },
    deleteUser: async (userId: number) => {
        return await axios.delete(`${API_URL}/api/user/delete/${userId}`, {
            withCredentials: true,
        });
    },

    /*
        LEMON SQUEEZY API
     */
    checkout: async (data: any) => {
        return await axios.post(`${API_URL}/api/lemon-squeezy/checkout`, data, {
            withCredentials: true,
        });
    },
    getSubscriptionUrl: async () => {
        return axios.get(`${API_URL}/api/lemon-squeezy/subscription`, {
            withCredentials: true,
        });
    },
    getSubscriptionInformation: async () => {
        return axios.get(`${API_URL}/api/lemon-squeezy/subscription/information`, {
            withCredentials: true,
        });
    },

    /*
        SOCIAL LINK API
     */
    getAllSocialLinks: async (userId: number) => {
        return axios.get(`${API_URL}/api/social/getAll/${userId}`, {
            withCredentials: true,
        });
    },
    addSocialLink: async (userId: number, platform: string, username: string) => {
        return axios.post(`${API_URL}/api/social/add/${userId}`, {
            platform: platform,
            username: username,
        }, {
            withCredentials: true,
        });
    },
    updateSocialLink: async (userId: number, platform: string, username: string) => {
        return axios.put(`${API_URL}/api/social/update/${userId}`, {
            platform: platform,
            username: username,
        }, {
            withCredentials: true,
        });
    },
    removeSocialLink: async (userId: number, platform: string) => {
        return axios.delete(`${API_URL}/api/social/delete/${userId}`, {
            data: {
                platform: platform,
            },
            withCredentials: true,
        });
    },
    playlistHttpError: (error: any) => {
        const useSnackbarStore = require("../stores/snackbarStore");
        const snack = useSnackbarStore.useSnackbarStore.getState();
        if (error.response.status === undefined) {
            snack.showSnackbar("Network Error", "error");
            return;
        }
        switch (error.response.status) {
            case 400:
                snack.showSnackbar("Max Playlist Limit", "error");
                break;
            case 403:
                snack.showSnackbar("Forbidden", "error");
                break;
            case 404:
                snack.showSnackbar("Playlist/Audio not found", "error");
                break;
            case 409:
                snack.showSnackbar("Audio already in Playlist", "error");
                break;
        }
    },

    /*
        BIO API
     */
    getBio: async (userId: number) => {
        return axios.get(`${API_URL}/api/bio/get?userId=${userId}`, {
            withCredentials: true,
        });
    },
    updateBio: async (userId: number, bio: string) => {
        return axios.post(`${API_URL}/api/bio/update`, {
            userId: userId,
            bio: bio,
        }, {
            withCredentials: true,
        });
    }
}

export default apiService;